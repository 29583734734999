<template>
  <template v-if="isWLPaused">
    <div class="row">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <div class="card h-100">
          <div class="card-header pb-0">
            <h4>Thoth $WRLD Mint</h4>
          </div>
          <div class="card-body text-center p-3">
            <div v-if="isAuthenticated">
              <div v-if="totalSupply == 800">
                <p class="text-sm">
                  Sold Out! Find our collection on
                  <a href="">Opensea</a>
                </p>
              </div>
              <div v-else>
                <div v-if="this.hasEnoughFundApproved">
                  <div>
                    <h6 class="text-md text-uppercase">1888 $WRLD per NFT</h6>
                    <p class="text-sm">Select your quantity</p>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        v-on:click="wrlddeincrement"
                        class="btn btn-lg btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                          />
                        </svg>
                      </button>
                      <button
                        type="button"
                        disabled
                        class="btn btn-lg btn-lite"
                      >
                        <strong class="text-dark text-lg">{{
                          this.wrldamount
                        }}</strong>
                      </button>
                      <button
                        type="button"
                        v-on:click="wrldincrement"
                        class="btn btn-lg btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-up"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                          />
                        </svg>
                      </button>
                    </div>
                    <h6></h6>
                  </div>
                  <div v-if="mintLoading">
                    <button
                      class="btn btn-lg btn-outline-primary"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                  <div v-else>
                    <button
                      class="btn btn-lg btn-outline-primary"
                      v-on:click="minter"
                    >
                      Mint
                    </button>
                  </div>
                </div>
                <div v-else>
                  <div v-if="approveLoading">
                    <button
                      class="btn btn-lg btn-outline-primary"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                  <div v-else>
                    <button class="btn btn-primary" v-on:click="approve">
                      Approve
                    </button>
                  </div>
                </div>
                <div v-if="this.wrldMinted">
                  <h6 class="text-success">
                    Congrats you have minted a MuseDAO Thoth!
                  </h6>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="text-sm">
                Please connect your wallet to proceed with mint.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card h-100">
          <div class="card-header pb-0">
            <h6>Session Transactions</h6>
          </div>
          <div class="card-body p-3">
            <div class="timeline timeline-one-side">
              <div class="timeline-block mb-3">
                <span class="timeline-step">
                  <div v-if="this.hasEnoughFundApproved">
                    <i class="material-icons text-success text-gradient text-lg"
                      >check</i
                    >
                  </div>
                  <div v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-exclamation-triangle text-danger"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                      />
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                      />
                    </svg>
                  </div>
                </span>
                <div class="timeline-content">
                  <h6 class="text-dark text-sm font-weight-bold mb-0">
                    Allowance Check
                  </h6>
                  <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    $WRLD Allowance to contract:
                    {{ getAllowanceInEther }}
                  </p>
                </div>
              </div>
            </div>
            <div class="timeline timeline-one-side">
              <div class="timeline-block mb-3">
                <span class="timeline-step">
                  <div v-if="this.hasEnoughFundApproved">
                    <i class="material-icons text-success text-gradient text-lg"
                      >check</i
                    >
                  </div>
                  <div v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-exclamation-triangle text-danger"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                      />
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                      />
                    </svg>
                  </div>
                </span>
                <div class="timeline-content">
                  <h6 class="text-dark text-sm font-weight-bold mb-0">
                    Approve
                  </h6>
                  <div v-if="this.hasEnoughFundApproved">
                    <div v-if="this.approveJob.success">
                      <a
                        v-bind:href="this.approveJob.etherScan"
                        target="_blank"
                      >
                        <p class="text-info font-weight-bold text-xs mt-1 mb-0">
                          Transaction on etherscan
                        </p>
                      </a>
                    </div>
                    <div v-else>
                      <p
                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"
                      >
                        Have enough allowance - Skipped
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-danger font-weight-bold text-xs mt-1 mb-0">
                      {{ this.approveJob.error }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="timeline timeline-one-side">
              <div class="timeline-block mb-3">
                <span class="timeline-step">
                  <div v-if="this.wrldMintJob.success">
                    <i class="material-icons text-success text-gradient text-lg"
                      >check</i
                    >
                  </div>
                  <div v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-exclamation-triangle text-danger"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                      />
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                      />
                    </svg>
                  </div>
                </span>
                <div class="timeline-content">
                  <h6 class="text-dark text-sm font-weight-bold mb-0">Mint</h6>
                  <div v-if="this.wrldMintJob.success">
                    <a v-bind:href="this.wrldMintJob.etherScan" target="_blank">
                      <p class="text-info font-weight-bold text-xs mt-1 mb-0">
                        Transaction on etherscan
                      </p>
                    </a>
                  </div>
                  <div v-else>
                    <p class="text-danger font-weight-bold text-xs mt-1 mb-0">
                      {{ this.wrldMintJob.error }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-4" />

    <div class="row mt-4">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <div class="card h-100">
          <div class="card-header pb-0">
            <h4>Thoth ETH Mint</h4>
          </div>
          <div class="card-body text-center p-3">
            <div v-if="isAuthenticated">
              <div v-if="totalSupply == 800">
                <p class="text-sm">
                  Sold Out! Find our collection on
                  <a href="">Opensea</a>
                </p>
              </div>
              <div v-else>
                <h6 class="text-md text-uppercase">0.1 ETH per nft</h6>
                <p class="text-sm">Select your quantity</p>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    v-on:click="deincrement"
                    class="btn btn-lg btn-primary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                      />
                    </svg>
                  </button>
                  <button type="button" disabled class="btn btn-lg btn-lite">
                    <strong class="text-dark text-lg">{{ this.amount }}</strong>
                  </button>
                  <button
                    type="button"
                    v-on:click="increment"
                    class="btn btn-lg btn-primary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-up"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                      />
                    </svg>
                  </button>
                </div>
                <div v-if="mintLoading">
                  <button
                    class="btn btn-lg btn-outline-primary"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                </div>
                <div v-else>
                  <button
                    class="btn btn-lg btn-outline-primary"
                    type="button"
                    v-on:click="ethminter"
                  >
                    Mint
                  </button>
                </div>
                <div v-if="this.minted">
                  <h6 class="text-success">
                    Congrats you have minted a MuseDAO Thoth!
                  </h6>
                </div>
                <h6></h6>
              </div>
            </div>
            <div v-else>
              <p class="text-sm">
                Please connect your wallet to proceed with mint.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card h-100">
          <div class="card-header pb-0">
            <h6>Session Transaction</h6>
          </div>
          <div class="card-body p-3">
            <div class="timeline timeline-one-side">
              <div class="timeline-block mb-3">
                <span class="timeline-step">
                  <div v-if="this.mintJob.success">
                    <i class="material-icons text-success text-gradient text-lg"
                      >check</i
                    >
                  </div>
                  <div v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-exclamation-triangle text-danger"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                      />
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                      />
                    </svg>
                  </div>
                </span>
                <div class="timeline-content">
                  <h6 class="text-dark text-sm font-weight-bold mb-0">Mint</h6>
                  <div v-if="this.mintJob.success">
                    <a v-bind:href="this.mintJob.etherScan" target="_blank">
                      <p class="text-info font-weight-bold text-xs mt-1 mb-0">
                        Transaction on etherscan
                      </p>
                    </a>
                  </div>
                  <div v-else>
                    <p class="text-danger font-weight-bold text-xs mt-1 mb-0">
                      {{ this.mintJob.error }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <!--  Whitelist-->
  <template v-else>
    <template v-if="isWLEnabledAddress">
      <div class="row">
        <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
          <div class="card h-100">
            <div class="card-header pb-0">
              <h4>Thoth WL $WRLD Mint</h4>
            </div>
            <div class="card-body text-center p-3">
              <div v-if="isAuthenticated">
                <div v-if="this.hasEnoughFundApproved">
                  <div>
                    <p class="text-sm text-warning">
                      Max mint for WL wallets is 5. Do not try to mint more,
                      transactions will fail.
                    </p>
                    <h6 class="text-md text-uppercase">1111 $WRLD per NFT</h6>
                    <p class="text-sm">Select your quantity</p>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        v-on:click="wlwrlddeincrement"
                        class="btn btn-lg btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                          />
                        </svg>
                      </button>
                      <button
                        type="button"
                        disabled
                        class="btn btn-lg btn-lite"
                      >
                        <strong class="text-dark text-lg">{{
                          this.wrldamount
                        }}</strong>
                      </button>
                      <button
                        type="button"
                        v-on:click="wlwrldincrement"
                        class="btn btn-lg btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-up"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                          />
                        </svg>
                      </button>
                    </div>
                    <h6></h6>
                  </div>
                  <div v-if="mintLoading">
                    <button
                      class="btn btn-lg btn-outline-primary"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                  <div v-else>
                    <button
                      class="btn btn-lg btn-outline-primary"
                      v-on:click="wlminter"
                    >
                      Mint
                    </button>
                  </div>
                </div>
                <div v-else>
                  <div v-if="approveLoading">
                    <button
                      class="btn btn-lg btn-outline-primary"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                  <div v-else>
                    <button class="btn btn-primary" v-on:click="approve">
                      Approve
                    </button>
                  </div>
                </div>
                <div v-if="this.wrldMinted">
                  <h6 class="text-success">
                    Congrats you have minted a MuseDAO Thoth!
                  </h6>
                </div>
              </div>
              <div v-else>
                <p class="text-sm">
                  Please connect your wallet to proceed with mint.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card h-100">
            <div class="card-header pb-0">
              <h6>Session $WRLD WL Transactions</h6>
              <!--          <p class="text-sm">-->
              <!--            <i class="material-icons text-sm text-success" aria-hidden="true"-->
              <!--              >north</i-->
              <!--            >-->
              <!--            <span class="font-weight-bold">24%</span>-->
              <!--            asdsdd-->
              <!--          </p>-->
            </div>
            <div class="card-body p-3">
              <div class="timeline timeline-one-side">
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <div v-if="this.hasEnoughFundApproved">
                      <i
                        class="material-icons text-success text-gradient text-lg"
                        >check</i
                      >
                    </div>
                    <div v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-exclamation-triangle text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                        />
                        <path
                          d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                        />
                      </svg>
                    </div>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">
                      Allowance Check
                    </h6>
                    <p
                      class="text-secondary font-weight-bold text-xs mt-1 mb-0"
                    >
                      $WRLD Allowance to contract:
                      {{ getAllowanceInEther }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="timeline timeline-one-side">
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <div v-if="this.hasEnoughFundApproved">
                      <i
                        class="material-icons text-success text-gradient text-lg"
                        >check</i
                      >
                    </div>
                    <div v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-exclamation-triangle text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                        />
                        <path
                          d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                        />
                      </svg>
                    </div>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">
                      Approve
                    </h6>
                    <div v-if="this.hasEnoughFundApproved">
                      <div v-if="this.approveJob.success">
                        <a
                          v-bind:href="this.approveJob.etherScan"
                          target="_blank"
                        >
                          <p
                            class="text-info font-weight-bold text-xs mt-1 mb-0"
                          >
                            Transaction on etherscan
                          </p>
                        </a>
                      </div>
                      <div v-else>
                        <p
                          class="text-secondary font-weight-bold text-xs mt-1 mb-0"
                        >
                          Have enough allowance - Skipped
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <p class="text-danger font-weight-bold text-xs mt-1 mb-0">
                        {{ this.approveJob.error }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline timeline-one-side">
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <div v-if="this.wrldMintJob.success">
                      <i
                        class="material-icons text-success text-gradient text-lg"
                        >check</i
                      >
                    </div>
                    <div v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-exclamation-triangle text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                        />
                        <path
                          d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                        />
                      </svg>
                    </div>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">
                      Mint
                    </h6>
                    <div v-if="this.wrldMintJob.success">
                      <a
                        v-bind:href="this.wrldMintJob.etherScan"
                        target="_blank"
                      >
                        <p class="text-info font-weight-bold text-xs mt-1 mb-0">
                          Transaction on etherscan
                        </p>
                      </a>
                    </div>
                    <div v-else>
                      <p class="text-danger font-weight-bold text-xs mt-1 mb-0">
                        {{ this.wrldMintJob.error }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
          <div class="card h-100">
            <div class="card-header pb-0">
              <h4>Thoth WL ETH Mint</h4>
            </div>
            <div class="card-body text-center p-3">
              <div v-if="isAuthenticated">
                <div v-if="totalSupply == 800">
                  <p class="text-sm">
                    Sold Out! Find our collection on
                    <a href="">Opensea</a>
                  </p>
                </div>
                <div v-else>
                  <p class="text-sm text-warning">
                    Max mint for WL wallets is 5. Do not try to mint more,
                    transactions will fail.
                  </p>
                  <h6 class="text-md text-uppercase">0.07 ETH per nft</h6>
                  <p class="text-sm">Select your quantity</p>
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      v-on:click="wldeincrement"
                      class="btn btn-lg btn-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                        />
                      </svg>
                    </button>
                    <button type="button" disabled class="btn btn-lg btn-lite">
                      <strong class="text-dark text-lg">{{
                        this.amount
                      }}</strong>
                    </button>
                    <button
                      type="button"
                      v-on:click="wlincrement"
                      class="btn btn-lg btn-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div v-if="mintLoading">
                    <button
                      class="btn btn-lg btn-outline-primary"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                  <div v-else>
                    <button
                      class="btn btn-lg btn-outline-primary"
                      type="button"
                      v-on:click="wlethminter"
                    >
                      Mint
                    </button>
                  </div>
                  <div v-if="this.minted">
                    <h6 class="text-success">
                      Congrats you have minted a MuseDAO Thoth!
                    </h6>
                  </div>
                  <h6></h6>
                </div>
              </div>
              <div v-else>
                <p class="text-sm">
                  Please connect your wallet to proceed with mint.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card h-100">
            <div class="card-header pb-0">
              <h6>Session WL Transaction</h6>
            </div>
            <div class="card-body p-3">
              <div class="timeline timeline-one-side">
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <div v-if="this.mintJob.success">
                      <i
                        class="material-icons text-success text-gradient text-lg"
                        >check</i
                      >
                    </div>
                    <div v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-exclamation-triangle text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                        />
                        <path
                          d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                        />
                      </svg>
                    </div>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">
                      Mint
                    </h6>
                    <div v-if="this.mintJob.success">
                      <a v-bind:href="this.mintJob.etherScan" target="_blank">
                        <p class="text-info font-weight-bold text-xs mt-1 mb-0">
                          Transaction on etherscan
                        </p>
                      </a>
                    </div>
                    <div v-else>
                      <p class="text-danger font-weight-bold text-xs mt-1 mb-0">
                        {{ this.mintJob.error }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row mt-4">
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card h-100">
            <div class="card-header pb-0"></div>
            <div class="card-body text-center p-3">
              <div v-if="isAuthenticated">
                <p class="text-sm">
                  Sorry you are not on the whitelist. Please wait for public
                  sale.
                </p>
              </div>
              <div v-else>
                <p class="text-sm">
                  Please connect your wallet to proceed with mint.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
import { getEllipsisTxt } from "../helpers/formatters.js";
import useMoralis from "../composables/useMoralis";
import Moralis from "moralis";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

export default {
  components: {},
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();
    return {
      getEllipsisTxt,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
  mounted() {
    const abi = [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "cap",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "_amount",
            type: "uint256",
          },
          {
            internalType: "uint8",
            name: "_claimNonce",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "_signature",
            type: "bytes",
          },
        ],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "claimEnabled",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [
          {
            internalType: "uint8",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "subtractedValue",
            type: "uint256",
          },
        ],
        name: "decreaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "enableSecondClaim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "addedValue",
            type: "uint256",
          },
        ],
        name: "increaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bool",
            name: "_claimEnabled",
            type: "bool",
          },
        ],
        name: "toggleClaim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const WRLDMindContract = "0x2AE3619596C2e570c75BF18b1f3bD72C78ca9c2C";
    const WRLDTokenContract = "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9";
    if (this.isAuthenticated) {
      const options = {
        chain: "0x1",
        address: WRLDTokenContract,
        function_name: "allowance",
        abi: abi,
        params: {
          owner: Moralis.User.current().get("ethAddress"),
          spender: WRLDMindContract,
        },
      };
      Moralis.Web3API.native.runContractFunction(options).then((data) => {
        this.allowance = data;
        if (this.allowance >= Moralis.Units.FromWei(1888)) {
          this.hasEnoughFundApproved = true;
        }
      });
    }
    const wrldMindAbi = [
      {
        inputs: [
          {
            internalType: "address",
            name: "_wlrdAddress",
            type: "address",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "approved",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "operator",
            type: "address",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "approved",
            type: "bool",
          },
        ],
        name: "ApprovalForAll",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_amount",
            type: "uint8",
          },
        ],
        name: "ethMint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "mintInitial",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address[]",
            name: "_initMintAddresses",
            type: "address[]",
          },
        ],
        name: "mintInitialAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        inputs: [],
        name: "pause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "_data",
            type: "bytes",
          },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "operator",
            type: "address",
          },
          {
            internalType: "bool",
            name: "approved",
            type: "bool",
          },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bytes32",
            name: "_root",
            type: "bytes32",
          },
        ],
        name: "setMerkleRoot",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_wallet",
            type: "address",
          },
        ],
        name: "setPayoutWallet",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bool",
            name: "_paused",
            type: "bool",
          },
        ],
        name: "setWhitelistPause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unpause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_amount",
            type: "uint8",
          },
          {
            internalType: "bytes32[]",
            name: "_proof",
            type: "bytes32[]",
          },
        ],
        name: "whitelistEthMint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_amount",
            type: "uint8",
          },
          {
            internalType: "bytes32[]",
            name: "_proof",
            type: "bytes32[]",
          },
        ],
        name: "whitelistWrldMint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "withdrawl",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint8",
            name: "_amount",
            type: "uint8",
          },
        ],
        name: "wrldMint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "getApproved",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_address",
            type: "address",
          },
        ],
        name: "getWhiteListMintCount",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "operator",
            type: "address",
          },
        ],
        name: "isApprovedForAll",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "merkleRoot",
        outputs: [
          {
            internalType: "bytes32",
            name: "",
            type: "bytes32",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        name: "numWhitelistMints",
        outputs: [
          {
            internalType: "uint8",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "ownerOf",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "payoutWallet",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bytes4",
            name: "interfaceId",
            type: "bytes4",
          },
        ],
        name: "supportsInterface",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
        ],
        name: "tokenByIndex",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
        ],
        name: "tokenOfOwnerByIndex",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "tokenURI",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "whitelistPaused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "wrld",
        outputs: [
          {
            internalType: "contract IWRLD_Token_Ethereum",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ];
    const options = {
      chain: "0x1",
      address: WRLDMindContract,
      function_name: "totalSupply",
      abi: wrldMindAbi,
      params: {},
    };
    Moralis.Web3API.native.runContractFunction(options).then((data) => {
      this.totalSupply = data;
    });

    const Thothoptions = {
      chain: "0x1",
      address: WRLDMindContract,
      function_name: "whitelistPaused",
      abi: wrldMindAbi,
      params: {},
    };
    Moralis.Web3API.native.runContractFunction(Thothoptions).then((data) => {
      this.isWLPaused = data;
    });

    if (
      this.WlAddress.filter((str) =>
        str
          .toLowerCase()
          .includes(Moralis.User.current().get("ethAddress").toLowerCase())
      ).length > 0
    ) {
      // If 5 greater then 5 mints, keep as false.
      this.isWLEnabledAddress = true;
    }
  },
  data() {
    return {
      allowance: 0,
      hasEnoughFundApproved: false,
      minted: false,
      wrldMinted: false,
      amount: 1,
      wrldamount: 1,
      mintLoading: false,
      approveLoading: false,
      approveJob: {
        status: "",
        data: null,
        etherScan: "",
        error: "",
        success: false,
      },
      mintJob: {
        status: "",
        data: null,
        etherScan: "",
        error: "",
        success: false,
      },
      wrldMintJob: {
        status: "",
        data: null,
        etherScan: "",
        error: "",
        success: false,
      },
      totalSupply: 0,
      isWLPaused: false,
      WlAddress: [
        "0x8234653486a78789200B8EF5E3C54c122cF883bb",
        "0xF8301F8E6D70d47cd0204323Af0F62dF701b67e6",
        "0x6601eB79573eF1B183a139fE1B23455Ed4f7a9b7",
        "0xe478177beB095906e04FdD4Ac9E01f2098d70aEF",
        "0xdAA34eEC58CAfacD48247A0d252d4569A6865d4D",
        "0xa5B7b4eb029295159D9b3df038B343a2169032d3",
        "0x624Ac7F9E01Ab447CbcB52119F31bB728770841e",
        "0xFB8a584aC0A8c752Be72D1bcC189868d23dbCBE5",
        "0x85b1e8D0C705CCD932659AB38e07A2CBDB6D5c47",
        "0x2bd200e5fc96b9fB6f5D68330eF393b4820D0006",
        "0x6b0d13E2aB7a6e1b40829afc1CDDD98d3551ce46",
        "0xc76Bb9cAF6328E0a02Fc810fe6e7Ae0c93FeEEf9",
        "0x203Ce478416C381998C3393778171138b83b0D3e",
        "0x55A55cd715CB468dc49f7A9C928b16aa79efd3d3",
        "0x14d76dD2de872ca66075058088e7B13931b0141C",
        "0xd057955a184782C065681f872bb88AA2C7531c14",
        "0xaDcE49e08484a9FE821CADAB2Bb6E7dDD159207F",
        "0xfE43c0aB21e45aA6461EfCcB147819565C56b568",
        "0x961c44c0aA6d85804a9808267504257B65C1F63F",
        "0x365f4144aa21380D66547c3A5293d6B1e71A21E9",
        "0xff18AB10F0E5012b529771AE3DD60d01404c1f62",
        "0x5ad1d8d209Ec370682f0F56086d7b22c8036a534",
        "0x04CD9cC35601bb748cBC98B549545F44068cDDeB",
        "0xF4D9531017c81f62C98C3ff1871b46caf2108B23",
        "0xde38c6964f840afeEB6D891cb4F6B132498579AE",
        "0xA01951F21Fa38e2B9e84893F2bFFC9e92E48482e",
        "0x37Cc389550a2a59E3Fee5EC4D26f7A696B4D1732",
        "0x5f396a08ab8101d0AeeA70875595e9cB6BA4c948",
        "0x5D22a8A3f7918E6d838A0a58aD4E73e0200Bd656",
        "0xcb6929A50ca9Fd4706E13B0CFE9F4611F5d210f8",
        "0x9a604173DEF05868d20a8E150c322598D5d92c4E",
        "0xbDF4c9C2F32210627b9aDB84019E7ccd0C29D9C4",
        "0x97d2a7Cd927eC9ECED64C092EB1B443Cde824Aea",
        "0x649c261C87B7cDD1C90c20c4fD95c3957d4146e1",
        "0x6F0B20443D05038dd24d0dBA77a6FE368773C730",
        "0xafF8bFF230D27b15b16Ae4F3FEf0089d82A707BC",
        "0x708C36d662f1f1930B66Fc46C3A7209861d67037",
        "0x1A605a87f956F8981683cdFf3D8bD2A421412915",
        "0x7DFaD91dc7e0b907B4e9cf41d90416F212eCF26A",
        "0xB4c76A8d1f012c103C7FFFCae092220933e3c58B",
        "0x385Ee5caA8178Ce0CF3449E926fF8A2D0A4A2e65",
        "0xe50844601d69177fFBC4Ed960c5ED2229Cc05Cf2",
        "0x55237b6be31904f82da11195139A1B7fbc4309f4",
        "0x005D74DBaDeeaf40F85168c476861C33af627d18",
        "0x26C393bc3846f9670966cF781802a984A033e508",
        "0x70032448B5D77FaD9d6aEd97DC03cA57e31297Cc",
        "0x7a02c16F8691203cF7A3664cdd4785df655378Cd",
        "0x9dfff42782AeeD09EdAC59a9Ec20b03812127C63",
        "0x04AF0C78d27d7a4cA597EAb5cd1DfD347821a564",
        "0xaf7FEBF7457dCC4085840b036Aaae8bd1AbaEa6C",
        "0x51F9AD2DF4638e052Ec12Ed74F2FCD85352244F7",
        "0xc14482897aC3c8C2b80cF64b9cDa9E84415c0069",
        "0xc86a67051B9A980622728546C0C50B6ec63eD475",
        "0xA82Db9E56893387b67A889621F40ccEDe24De08E",
        "0x1B8f4781d799D61C4f58dE2b57FEFF3b9094b6ee",
        "0x94D7C45dEc7F98be67b5de5B91Ca36Fd1e1D1132",
        "0x861a2F3f54b3856094A3Cf23Bf121f8059Bd228A",
        "0xef21D97F5Ed8adFA4023A4A5bC3e9e6330fA4576",
        "0x4a26f8feb631C3FD99E29116113F17c758976435",
        "0xb46bfBB7efacd9647f281B08b9fF17Bd0FD21ff6",
        "0xE3C7051F6082FB7e1ACd7183bDF63f23647d684a",
        "0x1dcb5dC2902aC496b83362aB419ad596566Ed312",
        "0x5E5a49d8F37D2Ce1765A54306383be89954E3E3a",
        "0x40C4C7DC73ce4196d9Cc599B4c72D64D170A902D",
        "0x5e76075f5E40d5C7F7fBD2153Ef7E8113fE6f65c",
        "0xBDd076dE1ea34d1D2964Dc56118bB40cD29F97Cc",
        "0xD728c83C4A1ca7D0B0886F8D1a115C644A65AD99",
        "0x57d77d6308AdC040b4F977eB545929BC99F3b0C7",
        "0xD1b5F7473640242afb562ccADe592Ec9ce863742",
        "0x7035f9473A8F9f41770Bb88d672EF08161b747e2",
        "0x32C134d679940bBF8aDE2ea4F3B4d556c5d01a5A",
        "0x8560c7F3D6d34FBA69819F23991dAbB7c574F204",
        "0x01a3Ee906f482b90287C8f38043a01d58374065d",
        "0x9bF6649f180b28bA60aBe887De111e9A4FdE93dB",
        "0x2F1042ED07eE89b50A0061217EabAC6D8A67e28C",
        "0x4a10f023d745c8770a0bBA069D4D9f3216850BDb",
        "0xEE0E8dA3D29b981F4f5890ef2FbF51a4cd56471e",
        "0xD080a3a3d57519a2dDee96ce1Cc98fe742354e1C",
        "0x03b05E68f97656A0c48e2181FF82f8744F99B85A",
        "0x9Da62A3cf46c73Db656d25B44446ff3f41eEB4b7",
        "0xF927684aEC8B65E89B0BbAac89040d0339e6b9fe",
        "0x74a8fBB9651dAc3BB9f2f0d7B1af9CA3dE9181CE",
        "0xe399a654Bbc275B0850A2e533e3b0569E26666A7",
        "0x9f0829a4a2f63c632bc5201f162c1b4e648999c7",
        "0x490D8842cb1480a35Ab7BA048A2C2BAee1931961",
        "0x483a3b616885ce3b2225b2afafe0cbe47b4be1b9",
        "0x22f057b5189d796e9b56159774a701b563280b2c",
        "0x8ad9dbc38ea6d4022dc6e93e2ba003ed364759c7",
        "0xa4E60aBe0c20D1c6e14556D340CF36868407dABD",
        "0x9aC65AA2722cAa26a9F496a8FA8407844D1B02B1",
        "0x9791E54B5A0123B77bE91a7fd42d4406Ed038a4A",
        "0x14C4200d7a410e9a1103130e8D717A195D09f401",
        "0xBD51ACc5cEd1c00cFC73D5CBcBa2E48a0E928Aae",
        "0xEB92E00a8A93C8B71E7003e7c143c31e585e1cac",
        "0xd7eab819d8b431cf90eb66cd8539c8b1f40c864a",
        "0x73400e763BbfdB50afd2AdF8F144160b7baE4EaD",
        "0x0a9b95edb069b49b4773ddd155bad24a5bd59df3",
        "0x6f55F4d3174c77D214B8FB3C9ac8B1A66DAbF67e",
        "0xc5caF890Bc074c314d272dD7F437031358CAa6D1",
        "0x319D3A318F6A99C9b7bcB54A45a61f5c5a3Ca31a",
        "0x5dF37F30bec1ea7D9b2F449E35cB53e98Cd6eBD6",
        "0x5666d355d55fc90Dc205F3d12cfC4f89bDa7C5f5",
        "0x7d1146E7812690cF366cB8cD532067694A1bb206",
        "0x18062ca8D8f3cf0Fab063587b648d551A1EF842E",
        "0xd79521f2b62a9800b546b22e891d546e25d8f0c0",
        "0xf4e7E6B600286fFba8154F5125420A0F78DB835d",
        "0x6A3d4920a4c00b7D4f0F15E51f713f5972415A26",
        "0x74adee5ac4f730ba84e022afc35f83076b756241",
        "0x7499DE64B726F00530eF096b2Aed9b8e05891eA0",
        "0x2dF95d27a2F9d3591Ed634D39849bCDdff01CE22",
        "0x8d93F6aA895c46AB82174669D4d2ca9165796cb6",
        "0xE72D02B5f0CFd8C6073eF8Fa5E28A594962913ba",
        "0x5fec3b40af6ee593e2a4a77d78374c1c8e787019",
        "0x0B6b3165095800ce2e43c56cBD4eC015f2b590Dc",
        "0xb1b20b15eb6d9e0208ec455ab2cbba8c1c665afe",
        "0x94317e486CEd1Db68227d7eFD28a2aCeE11FE481",
        "0xB2A9718b641b472cDbFe857e4D05D27E270aEe7f",
        "0xC3f719804d6Cce8d4aF3E24b72608373c92177Ea",
        "0x1c799D244935DB20DA52cc43a2b833609Ac8493B",
        "0x18019A5F4A51a2b31088230ac65783EC409a26F7",
        "0xe8117e87f2f627364e8f757f8d61be71ac79cca0",
        "0xD285f154a1ec0555aD4b8438D125529010C6E052",
        "0x33fe4A6B3E79615067E75bDa042F8820D7666d82",
        "0x4a3981570cb0390A1Be32dd5dd5a211Bf353E496",
        "0x7832E8979FAd2cdbD9fC3bffC5853873e354267D",
        "0x448BC8B5f99C45D6433698C8a11D589aE28Af73D",
        "0xcE3B74e1cBBf5D62A51c98cFB2e6AFDd133A2ABF",
        "0x5dBC6AF35bc3ae54d145B51e78A287beb0C8528E",
        "0x109E58Bf171a19dDc0d0b9E4B37BfA72E626873E",
        "0x069513afccb315a4847491102b284ed9908ee8d3",
        "0x81A9b651c1A974237Bf154553d671f8091297c02",
        "0xBF1bD662Fb937804f97481c2BC6986F9DA60f258",
        "0x7f76D15c57Df1A4a6a9b92a22bCB67B19ec20231",
        "0xef8B19376231F1aE485f29c155B51CA94A9d0a25",
        "0x33F851cC3E3d3F2f5725BC0C6b734F3AAc22E90a",
        "0x700dB2FA52f3f05b32E4a30FF91826362fa8c447",
        "0x52691b5AB413863BaC901f63cB7e2Ce8ab7cC049",
        "0xc8C5772C3d9260Bcf77A7b88540BB4BB35379a2E",
        "0xCAE44f87d9A8fd6aFc20CCAE7Cdc0011d38aDE51",
        "0x9ceE1d71276001Ce5fa261cCf96A32199f222F63",
        "0xC048930329877a0449115E5c4E2700daa926bC8C",
        "0x0Fa3fc1Ba1d80d59295e7927EE0C8D994138f880",
        "0x5F4D8f9A0fBA855349D3C69f23701D25675be714",
        "0x51be5EE836c68c1DE690AcD0f2518c258e819859",
        "0xa89b7Df5086AF472Abe66Ac78CbBFA9e6eD53bfa",
        "0xA8f6669cdaA72555231C8100B29DE4021A029819",
        "0xc7e4aDE5255288528F9F24820aE4B12A92f54665",
        "0x1D399E3Cd8F0dC4a237b929b8f92755aB001FbEC",
        "0x2171bE2564b858c5078ae4217fA56dB457C109eb",
        "0x813a2bcd547f6ae48bd1e6054f82b9b59530a246",
        "0xe00f9d5A203E22a7530efB18c5fCA7f7937073e3",
        "0xA3E72a818BE3104eaDc98B5226fcd84306a4aC2f",
        "0xe2b34bbf669096a794397376Fb0587e98eB81016",
        "0x4A6bB535323d238F50A6d6F3dE8b9a116E379190",
        "0xa830488a25751f7da0f5488f714c96f0035687de",
        "0x7f05B4aAc074db8224A08Cf2131aEf8CCaC2376C",
        "0x0Db9624d8c681bc6331281ae6E393C78BC9ded63",
        "0xf63446330977873c2C1Dc63da2451935f0281288",
        "0xB42d514aCe2e26E4e773BFA05E1F56951503e241",
        "0x7f88b0B693e8C2458a775FA313a9AaD0bA2f2e08",
        "0x9878a805031185737D9c19dc23f782fb9f408fe4",
        "0xb4C374F1837ef355cA308b8024Fd4555aEF3bbE7",
        "0x13a0EFE41Dafb205C9cC12bC7aaaB6e6765445ba",
        "0x66B88C2BA33d3281cF9F0812D6F8dFE3509cACA9",
        "0x9021748B9fB35d7d9E82Cad87d09c535F8f389DA",
        "0x95BA50660e367CC89B8eA748126bfCC5d1Ce3865",
        "0xB95A3Bf65c10A7ee64AF73b028c667240e4A9955",
        "0x65Af2CBc64465b7955C2d8Cc5BAdF4414423F677",
        "0x6B5b39B28729be977a4De0c4533e81acE9437778",
        "0xdA9Ecf2BB7A3C7E209c092AB1Ed838E0F4eFa26B",
        "0x0dE6886471E3d3Adbb8643376BEf78b5d7A82EE7",
        "0x33DD1297f7719918B1A6bB148B24086d597a0298",
        "0x6b789b37c847ec8a9d1a747aede90b0edce9bfda",
        "0x878332c08C852ab510D37774b8846B47F7577f1f",
        "0x3a6c611d769a0f30a8fd6b515a15cc6caedb8e7a",
        "0x1ac14830ef555b3dfe57380a2161eabbc66b48b1",
        "0xB3E6e340A13F102C55d67eC2FcAf1D9e9bBe5a14",
        "0xd1D645aB58657575169d5E4385E62ac3D7182030",
        "0xfc1D080842F78C29AfE936712e807095f499e2F8",
        "0x8F3644dd6bf51cB777F5913024B1e3E7c29f1139",
        "0xE8d30aD5Ec74aE1732fe3793147D74B8Dede6CDA",
        "0xa871477335e56897e89d28122e993d41f38ae803",
        "0x81A181e3024199501f6245f71769829bc3799e55",
        "0x2AF0C00279de5521C5DA39A8A460DA39E635C191",
        "0xD92dA91c48cAE1D8309DA06150751C11D049613E",
        "0x0f31F9f7d0fa6eb690FBAAF8CC2af9E8bE0b0A2B",
        "0x204628fdF521531E8DF520968dFfD0Ee19fcEf4D",
        "0x42d2Ea74f03366656aB41Cfe10ac6683452a8Ccd",
        "0x4ba4215C1b3EC7d9BD1D05eA6a68cee18Ec6ee5F",
        "0xcB76C144dED19A3c35CeF9cD97D3881B945A7056",
        "0x5935c09c6adcbbd5dcbef0eac5f7eb417a91a931",
        "0xFC0F5a77Cc71caA386FDbCe65BE7fA06821D5D5d",
        "0x9B31a51DF352C7Cc0372b013172DaD16Cb40E314",
        "0xf6725261622450d6A923EF4EE0736561c05D3540",
        "0x0E5AC5E54b8310d4ce2407b200D4BCc7169c7C43",
        "0x311a246119135b0dDb610feC9087b3338c856B07",
        "0xf3945e3247FA377cAa5B29896D2A4c6141CB50e6",
        "0x16DB9B9929CFd32B19bF5Be8f2F9493100d20Ad5",
        "0xbBa1055bec61C650Ea6e893555c58802D55a1dE7",
        "0xf1bd99910770774504c2c9127ae6ebce2c437744",
        "0xeF0B939361A6403C86Ecb22e90e344605018E7Ff",
        "0xd178221f778a3f06a8fa98c9804ef68548639514",
        "0x9c0fbcbace2df3495ed25Eff0f37Abb60dDaFfF0",
        "0xb00e3329a32cd910b49b713bf47c9a2674fc964a",
        "0x9ffb28b6a7a81ce72dba9893865117b265098184",
        "0x7c4899107b1cba3833e6619576500d273304285e",
        "0x92ef4baf847f2045c0a7d90ed6b3c25cc088da57",
        "0x9fb470ae1278be58fe7511f465812d44696f98f1",
        "0xc5ccd25838551d076b0365c123f49c7bdaa1ef34",
        "0xfb91a0d6dff39f19d0ea9c988ad8dfb93244c40b",
        "0x08fa99a691bc8a56328a1cfd95020e4ad9eeece3",
        "0xf4c3069b42888e679ceae20ec88c497e61d27668",
        "0xf58ba5008983a96a60d7d7442860f79735b9fbba",
        "0x99b29366c13b0cae8bf023d5e09aa5ded6b56c2e",
        "0x94aa50fe3c1ad32b0419004eee4f278ca3908876",
        "0xeb97952c39c36ace5af7b2919ab058ab9bbcad12",
        "0x4c47ab777f1f64d1f3d6efbf1cc7ab5a5224af4a",
        "0x006d26d1b640dbb9f9783ce0fdd700639adb11a3",
        "0xad531c6758d008bdfa110b6dc412076a59ac6668",
        "0x0ea9fbc98126ce00e69251542b6fc4691b3902bb",
        "0xb00cd8e790ec45971a04695849a17a647eb74463",
        "0xab8346293f2353df31ad8b934eabec1a16e9a745",
        "0x9b1ae7168be6d618ce1ec5aeaab6f588afa18e54",
        "0xedb3be13fd84fd393e2e0d77daf0926817eb04d8",
        "0x587b1e3d880e521862a868eb62efbb34956d5e99",
        "0xaf4ac2f4c6787e81db915415eba3425b39fdf163",
        "0x5af06e9047a25ee7e8970f27308ad20042d6a712",
        "0x7002c4c3a0c5dd007cd323ce1b43d128d04bd63e",
        "0xd644562dd5e4994344509a19967237891b4a9cc7",
        "0x5d5fc5b584041594bbd289a68c84bc5f85ba6736",
        "0x397d373fad65d4090543d1c3da832e8c8491c916",
        "0x5f4934fc98eae6061a01868a4700c53c1ffb7d2e",
        "0x9a80c6437ad9b6e7a1608814cbab93deeecf388a",
        "0x5661e337678251e632dc97789e514a19f5b94e0f",
        "0x97e11916b77c00801de0cc202b38dd0ce0d6980a",
        "0xe19825e5e4804ce447cc695971b92603137c614f",
        "0x8ea650d831eaca6b84360fa9db45f481e0622a1f",
        "0x8c50682780e584255fed034c8046d35af5fdc2d9",
        "0xf7aee24c8065182f48b334dc883c62fdaaa60b5e",
        "0x41cb67fc93cf6bac7e504341d19e0c7e04c342e3",
        "0xcc7143aabdb43aa7f14e3e1ddcca689906b3449f",
        "0x47be369d48fe2adf16b2825c499cdeb03ae82723",
        "0x8ee6b6cfe10747f3bd10987018be05e8bc029ac8",
        "0x21dc1ade6498739f95b69d43bb4ad6063d2ad7b1",
        "0xdcaf274706c7cfc53d53a95ba95e624252e6eae0",
        "0x5e72cf497b6eb681a45abc37f05abec894f02df3",
        "0x2780d3905dfa72de02c07244a11efc62c70b470a",
        "0xddc094b6eac49d1ebb54d2bca924394d4c84d163",
        "0xbc44b97d00df23ee50c8e5c89f6a5eb00d81de4e",
        "0xc51f6bc6c1e1537026314e42d6100acb6207bc50",
        "0x8c470aa40c61ef77559d21b16dcd8195417e48ea",
        "0x3e483f5516564e8078b7108a8fba84762f6eb547",
        "0x5344b925e708ad57a5fcdc452938bc3c158f623b",
        "0x0c57b28c9766932524a3566ca3cfe76ba73608ce",
        "0xee2a4c655ea792d92f12a3caea21188be6dea808",
        "0xb394f1aa30ba874df3ae6097bf4c2474b2d8f3ac",
        "0x3058a0d5e8e1a7b15dbf13eb3d411ee3efea70d9",
        "0xcc47b7d3129fd40e7c4433083851db5720742c94",
        "0x3890c6b13de444029c30621e911f0d5b2361810b",
        "0xd79c8617a5275a3ed93012fd5f93245ce71131fc",
        "0xef5affffa431881c141b50a7d1534ec008513cd0",
        "0xfd27c37117e33b51ae6522271a8f97d95502e79f",
        "0x81b1f3898b50be2deeda5c07cefb6dd37baaab98",
        "0x17775cf8b28f669e143f9abdab5ba4e099a75d79",
        "0x9399595c5237cbe96864baa35af68f1c93100dea",
        "0x4a469ae6712645bc5a3d3964c029d4cd02a40c25",
        "0x7ea6607175cd89879664dbd2d7323a8c7d8665f0",
        "0x4e2c7dbacd7f8a19426f1837fd3d8acbdc15e5bf",
        "0x25bae2c350b5c5c8760d20b10567b0b547154c1b",
        "0x60676e210d3117ce849c858b1eba42cdaa8d8949",
        "0xeb5ed50c8f66e4161516ea8a447fe0435d8d518f",
        "0x4aef3a62fce741964d652c650983fdcb6af7f669",
        "0xb630d64f8ad83756bcaeb41a63435f1aed49e18a",
        "0x809e7dE373abcE35372584f8739dEB644985f1f8",
        "0x21fE3Dfe9968FfAf7c4D581834179F6c63a6944B",
        "0x85618d9B4a1cC394e3adca5b9e4c73953c743aeA",
        "0xFB8a584aC0A8c752Be72D1bcC189868d23dbCBE5",
        "0xd0C50A5502fd2942B7BC616B3B6996eABBF56dae",
        "0xa465e69aea2d50bd3788921fea57629c92204306",
        "0x7E477948F739fd0F1595AC81b535182af6Baab26",
        "0xF9A2A43092eD46842dB1B72F1E771D4b42D3fbC8",
        "0xb0829F60d24E3f8BbD169364845C3C3D60D1001d",
        "0xDAf23e9BC9B21830f133fad1c717472bddE87A6C",
      ],
      isWLEnabledAddress: false,
    };
  },
  computed: {
    getAllowanceInEther() {
      return Moralis.Units.FromWei(this.allowance);
    },
  },
  methods: {
    async approve() {
      const self = this;
      self.approveLoading = true;
      // Reset
      self.approveJob.status = "";
      self.approveJob.error = "";
      self.approveJob.etherScan = "";
      self.approveJob.success = false;

      const abi = [
        {
          inputs: [],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "spender",
              type: "address",
            },
          ],
          name: "allowance",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "cap",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
            {
              internalType: "uint8",
              name: "_claimNonce",
              type: "uint8",
            },
            {
              internalType: "bytes",
              name: "_signature",
              type: "bytes",
            },
          ],
          name: "claim",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "claimEnabled",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [
            {
              internalType: "uint8",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "subtractedValue",
              type: "uint256",
            },
          ],
          name: "decreaseAllowance",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "enableSecondClaim",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "addedValue",
              type: "uint256",
            },
          ],
          name: "increaseAllowance",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "mint",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bool",
              name: "_claimEnabled",
              type: "bool",
            },
          ],
          name: "toggleClaim",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "transfer",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "transferFrom",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      const WRLDMindContract = "0x2AE3619596C2e570c75BF18b1f3bD72C78ca9c2C";
      const WRLDTokenContract = "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9";
      if (this.isAuthenticated && !this.hasEnoughFundApproved) {
        const options = {
          chain: "0x1",
          contractAddress: WRLDTokenContract,
          functionName: "approve",
          msgValue: 0,
          abi: abi,
          params: {
            spender: WRLDMindContract,
            amount: Moralis.Units.ETH(28320),
          },
        };
        Moralis.executeFunction(options)
          .then(function (data) {
            self.hasEnoughFundApproved = true;
            self.approveJob.status = "Success";
            self.approveJob.data = data;
            self.approveJob.etherScan =
              "https://etherscan.io/tx/" + data["transactionHash"];
            self.approveJob.success = true;
            self.approveLoading = false;
          })
          .catch(function (error) {
            self.hasEnoughFundApproved = false;
            self.approveJob.status = "Error";
            self.approveJob.error = error.message;
            self.approveJob.success = false;
            self.approveLoading = false;
          });
      }
    },
    async minter() {
      const self = this;
      self.mintLoading = true;
      // Reset
      self.wrldMintJob.status = "";
      self.wrldMintJob.error = "";
      self.wrldMintJob.etherScan = "";
      self.wrldMintJob.success = false;

      const wrldMindAbi = [
        {
          inputs: [
            {
              internalType: "address",
              name: "_wlrdAddress",
              type: "address",
            },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "ethMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "mintInitial",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_initMintAddresses",
              type: "address[]",
            },
          ],
          name: "mintInitialAddress",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
            {
              internalType: "bytes",
              name: "_data",
              type: "bytes",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes32",
              name: "_root",
              type: "bytes32",
            },
          ],
          name: "setMerkleRoot",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_wallet",
              type: "address",
            },
          ],
          name: "setPayoutWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bool",
              name: "_paused",
              type: "bool",
            },
          ],
          name: "setWhitelistPause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "transferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistEthMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistWrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdrawl",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "wrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getWhiteListMintCount",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "merkleRoot",
          outputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "numWhitelistMints",
          outputs: [
            {
              internalType: "uint8",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "ownerOf",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "payoutWallet",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes4",
              name: "interfaceId",
              type: "bytes4",
            },
          ],
          name: "supportsInterface",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenOfOwnerByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "tokenURI",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "whitelistPaused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "wrld",
          outputs: [
            {
              internalType: "contract IWRLD_Token_Ethereum",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];
      const WRLDMindContract = "0x2AE3619596C2e570c75BF18b1f3bD72C78ca9c2C";
      if (this.isAuthenticated && this.hasEnoughFundApproved) {
        const options = {
          chain: "rinkeby",
          contractAddress: WRLDMindContract,
          functionName: "wrldMint",
          msgValue: 0,
          abi: wrldMindAbi,
          params: {
            _amount: this.wrldamount,
          },
        };
        Moralis.executeFunction(options)
          .then(function (data) {
            self.wrldMinted = true;
            self.wrldMintJob.status = "Success";
            self.wrldMintJob.data = data;
            self.wrldMintJob.etherScan =
              "https://etherscan.io/tx/" + data["transactionHash"];
            self.wrldMintJob.success = true;
            self.mintLoading = false;
          })
          .catch(function (error) {
            self.wrldMinted = false;
            self.wrldMintJob.status = "Error";
            self.wrldMintJob.error = error.message;
            self.wrldMintJob.success = false;
            self.mintLoading = false;
          });
      }
    },
    async ethminter() {
      const self = this;
      self.mintLoading = true;
      // Reset
      self.mintJob.status = "";
      self.mintJob.error = "";
      self.mintJob.etherScan = "";
      self.mintJob.success = false;

      const wrldMindAbi = [
        {
          inputs: [
            {
              internalType: "address",
              name: "_wlrdAddress",
              type: "address",
            },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "ethMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "mintInitial",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_initMintAddresses",
              type: "address[]",
            },
          ],
          name: "mintInitialAddress",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
            {
              internalType: "bytes",
              name: "_data",
              type: "bytes",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes32",
              name: "_root",
              type: "bytes32",
            },
          ],
          name: "setMerkleRoot",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_wallet",
              type: "address",
            },
          ],
          name: "setPayoutWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bool",
              name: "_paused",
              type: "bool",
            },
          ],
          name: "setWhitelistPause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "transferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistEthMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistWrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdrawl",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "wrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getWhiteListMintCount",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "merkleRoot",
          outputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "numWhitelistMints",
          outputs: [
            {
              internalType: "uint8",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "ownerOf",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "payoutWallet",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes4",
              name: "interfaceId",
              type: "bytes4",
            },
          ],
          name: "supportsInterface",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenOfOwnerByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "tokenURI",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "whitelistPaused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "wrld",
          outputs: [
            {
              internalType: "contract IWRLD_Token_Ethereum",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];
      const WRLDMindContract = "0x2AE3619596C2e570c75BF18b1f3bD72C78ca9c2C";
      if (this.isAuthenticated) {
        const options = {
          chain: "rinkeby",
          contractAddress: WRLDMindContract,
          functionName: "ethMint",
          msgValue: this.amount * Moralis.Units.ETH(".1"),
          abi: wrldMindAbi,
          params: {
            _amount: this.amount,
          },
        };
        Moralis.executeFunction(options)
          .then(function (data) {
            self.minted = true;
            self.mintJob.status = "Success";
            self.mintJob.data = data;
            self.mintJob.etherScan =
              "https://etherscan.io/tx/" + data["transactionHash"];
            self.mintJob.success = true;
            self.mintLoading = false;
          })
          .catch(function (error) {
            self.minted = false;
            self.mintJob.status = "Error";
            self.mintJob.error = error.message;
            self.mintJob.success = false;
            self.mintLoading = false;
          });
      }
    },
    async wlminter() {
      const self = this;
      self.mintLoading = true;
      // Reset
      self.wrldMintJob.status = "";
      self.wrldMintJob.error = "";
      self.wrldMintJob.etherScan = "";
      self.wrldMintJob.success = false;

      const wrldMindAbi = [
        {
          inputs: [
            {
              internalType: "address",
              name: "_wlrdAddress",
              type: "address",
            },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "ethMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "mintInitial",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_initMintAddresses",
              type: "address[]",
            },
          ],
          name: "mintInitialAddress",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
            {
              internalType: "bytes",
              name: "_data",
              type: "bytes",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes32",
              name: "_root",
              type: "bytes32",
            },
          ],
          name: "setMerkleRoot",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_wallet",
              type: "address",
            },
          ],
          name: "setPayoutWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bool",
              name: "_paused",
              type: "bool",
            },
          ],
          name: "setWhitelistPause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "transferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistEthMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistWrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdrawl",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "wrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getWhiteListMintCount",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "merkleRoot",
          outputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "numWhitelistMints",
          outputs: [
            {
              internalType: "uint8",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "ownerOf",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "payoutWallet",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes4",
              name: "interfaceId",
              type: "bytes4",
            },
          ],
          name: "supportsInterface",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenOfOwnerByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "tokenURI",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "whitelistPaused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "wrld",
          outputs: [
            {
              internalType: "contract IWRLD_Token_Ethereum",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];
      const WRLDMindContract = "0x2AE3619596C2e570c75BF18b1f3bD72C78ca9c2C";
      const proof = self.buildMerkelTree(self.WlAddress);
      if (this.isAuthenticated && this.hasEnoughFundApproved) {
        const options = {
          chain: "rinkeby",
          contractAddress: WRLDMindContract,
          functionName: "whitelistWrldMint",
          msgValue: 0,
          abi: wrldMindAbi,
          params: {
            _amount: this.wrldamount,
            _proof: proof,
          },
        };
        Moralis.executeFunction(options)
          .then(function (data) {
            self.wrldMinted = true;
            self.wrldMintJob.status = "Success";
            self.wrldMintJob.data = data;
            self.wrldMintJob.etherScan =
              "https://etherscan.io/tx/" + data["transactionHash"];
            self.wrldMintJob.success = true;
            self.mintLoading = false;
          })
          .catch(function (error) {
            self.wrldMinted = false;
            self.wrldMintJob.status = "Error";
            self.wrldMintJob.error = error.message;
            self.wrldMintJob.success = false;
            self.mintLoading = false;
          });
      }
    },
    async wlethminter() {
      const self = this;
      self.mintLoading = true;
      // Reset
      self.mintJob.status = "";
      self.mintJob.error = "";
      self.mintJob.etherScan = "";
      self.mintJob.success = false;

      const wrldMindAbi = [
        {
          inputs: [
            {
              internalType: "address",
              name: "_wlrdAddress",
              type: "address",
            },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "ethMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "mintInitial",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_initMintAddresses",
              type: "address[]",
            },
          ],
          name: "mintInitialAddress",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
            {
              internalType: "bytes",
              name: "_data",
              type: "bytes",
            },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes32",
              name: "_root",
              type: "bytes32",
            },
          ],
          name: "setMerkleRoot",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_wallet",
              type: "address",
            },
          ],
          name: "setPayoutWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bool",
              name: "_paused",
              type: "bool",
            },
          ],
          name: "setWhitelistPause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "transferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistEthMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
            {
              internalType: "bytes32[]",
              name: "_proof",
              type: "bytes32[]",
            },
          ],
          name: "whitelistWrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdrawl",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint8",
              name: "_amount",
              type: "uint8",
            },
          ],
          name: "wrldMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getWhiteListMintCount",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "merkleRoot",
          outputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "numWhitelistMints",
          outputs: [
            {
              internalType: "uint8",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "ownerOf",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "payoutWallet",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes4",
              name: "interfaceId",
              type: "bytes4",
            },
          ],
          name: "supportsInterface",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "index",
              type: "uint256",
            },
          ],
          name: "tokenOfOwnerByIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "tokenURI",
          outputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "whitelistPaused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "wrld",
          outputs: [
            {
              internalType: "contract IWRLD_Token_Ethereum",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];
      const WRLDMindContract = "0x2AE3619596C2e570c75BF18b1f3bD72C78ca9c2C";
      const proof = self.buildMerkelTree(self.WlAddress);
      if (this.isAuthenticated) {
        const options = {
          chain: "rinkeby",
          contractAddress: WRLDMindContract,
          functionName: "whitelistEthMint",
          msgValue: this.amount * Moralis.Units.ETH(".07"),
          abi: wrldMindAbi,
          params: {
            _amount: this.amount,
            _proof: proof,
          },
        };
        Moralis.executeFunction(options)
          .then(function (data) {
            self.minted = true;
            self.mintJob.status = "Success";
            self.mintJob.data = data;
            self.mintJob.etherScan =
              "https://etherscan.io/tx/" + data["transactionHash"];
            self.mintJob.success = true;
            self.mintLoading = false;
          })
          .catch(function (error) {
            self.minted = false;
            self.mintJob.status = "Error";
            self.mintJob.error = error.message;
            self.mintJob.success = false;
            self.mintLoading = false;
          });
      }
    },
    increment() {
      if (this.amount != 10) {
        this.amount = this.amount + 1;
      }
    },
    deincrement() {
      if (this.amount != 1) {
        this.amount = this.amount - 1;
      }
    },
    wrldincrement() {
      if (this.wrldamount != 10) {
        this.wrldamount = this.wrldamount + 1;
      }
    },
    wrlddeincrement() {
      if (this.wrldamount != 1) {
        this.wrldamount = this.wrldamount - 1;
      }
    },
    wlincrement() {
      if (this.amount != 5) {
        this.amount = this.amount + 1;
      }
    },
    wldeincrement() {
      if (this.amount != 1) {
        this.amount = this.amount - 1;
      }
    },
    wlwrldincrement() {
      if (this.wrldamount != 5) {
        this.wrldamount = this.wrldamount + 1;
      }
    },
    wlwrlddeincrement() {
      if (this.wrldamount != 1) {
        this.wrldamount = this.wrldamount - 1;
      }
    },
    buildMerkelTree(accounts) {
      let merkleTree = new MerkleTree(accounts, keccak256, {
        hashLeaves: true,
        sortPairs: true,
      });
      let hexProof = merkleTree.getHexProof(
        keccak256(Moralis.User.current().get("ethAddress"))
      );
      return hexProof;
    },
  },
};
</script>

<style></style>
